/* eslint-env jquery */

"use strict";

(function () {
    const Swiper = require("swiper").default;
    const Pagination = require("swiper").Pagination;

    if (!window.categoryGridItemSwiperInitialized) {
        const carousels = $(".category-grid-items-carousel-swiper");

        carousels.each(function (index, carousel) {
            const carouselId = $(carousel).data("id");
            const selector = `.${carouselId} .swiper`;
            const paginationSelector = `.${carouselId} .swiper-pagination`;
            const numberOfSlides = $(selector + " .swiper-wrapper").children().length;

            if (numberOfSlides <= 8) {
                $("." + carouselId).addClass("hide-pagination-desktop");
            }

            if (numberOfSlides <=2) {
                $("." + carouselId).addClass("hide-pagination-mobile");
            }

            // eslint-disable-next-line no-unused-vars
            const categoryGridItemSwiper = new Swiper(selector, {
                modules: [Pagination],
                pagination: {
                    el: paginationSelector,
                    clickable: true,
                },
                breakpoints: {
                    0: {
                        loop: true,
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                        spaceBetween: 8
                    },
                    1024: {
                        slidesPerView: 8,
                        slidesPerGroup: 8,
                        spaceBetween: 8
                    },
                },
            });
        });
        window.categoryGridItemSwiperInitialized = true;
    }
})();
